import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Preferences } from "@capacitor/preferences";
import { BehaviorSubject, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SwitchboardClientOptions } from "./types";

@Injectable({
	providedIn: "root",
	})
export class SwitchboardAuth {
	token$: BehaviorSubject<string>;
	isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private options: SwitchboardClientOptions = environment.switchboardOptions;
	isLoggingOut = false;
	constructor(
		private http: HttpClient,
		private router: Router,
	) {
		// this.token = jsCookie.get("token");
		// const initialToken = this.getToken();
		this.initializeToken();
	}
	
	async initializeToken() {
		this.token$ = new BehaviorSubject(null);
		const token = await Preferences.get({key: "access_token"});
		this.setToken(token.value);
		this.token$.pipe(
		).subscribe(async (token) => {
			if (this.isLoggingOut) return;
			if (token) {
				this.isAuthenticated$.next(true);
			} else {
				await this.attemptRefreshToken();
			}
		});

	}

	async attemptRefreshToken(errorCallback?: () => void) {
		const token = await Preferences.get({key: "refresh_token"});
		if (!token.value) {
			if (errorCallback) {
				errorCallback();
			} else {
				this.isAuthenticated$.next(false);
				// check if this.router.url is a main page.. 
				// if not, don't redirect to login page (because you are already on an unauthenticated route)
				if (!this.router.url.includes("/main")) return;
				this.router.navigateByUrl("/login");
			}
			return;
		}
		this.http.post<any>(this.options.authServer + "/user/refresh_token", {
			refreshToken: token.value
		}).subscribe(async (res) => {
			await this.saveTokens({token: res.accessToken, refresh_token: res.refreshToken});
			this.setToken(res.accessToken);
		}, async (err) => {
			console.error("Failed to refresh token", err);
			// await this.clearToken();
			if (errorCallback) {
				errorCallback();
			} else {
				this.isAuthenticated$.next(false);
				this.router.navigateByUrl("/login");
			}
		});
	}

	login(email: string, password: string) {
		return this.http
			.post<any>(this.options.authServer + "/user/login", {
				email,
				password,
			})
			.pipe(
				switchMap(async (res) => {
					console.log("login success!", res);
					await this.saveTokens({token: res.accessToken, refresh_token: res.refreshToken});
					this.setToken(res.accessToken);
					return this.token$.pipe(
						take(1),
						map((token) => !!token)
					);
				}),
				catchError((err) => {
					console.error("Failed to login", err);
					return of(false);
				})
			);
	}

	loginWithRemoteToken(token: string, email: string, source: string) {
		console.log("logging in service");
		return this.http
			.post<any>(this.options.authServer + "/user/loginWithRemoteToken", {
				email,
				token,
				source,
			})
			.pipe(
				map((res) => {
					console.log("login success!", res);
					this.setToken(res.token);
					// location.reload(); // force graphql to pick up the token.
				}),
				catchError((err) => {
					console.error("Failed to login", err);
					return of(false);
				})
			);
	}

	getToken() {
		// console.log("getting token", this.token$.value);
		return this.token$.value;
	}

	hasToken() {
		return this.getToken() && this.getToken().length > 0;
	}

	async setToken(token: string) {
		if (!token) {
			this.clearToken();
			return;
		}
		this.token$.next(token);
	}

	async saveTokens({token, refresh_token}: {token: string, refresh_token: string}) {
		await Preferences.set({key: "access_token", value: token});
		await Preferences.set({key: "refresh_token", value: refresh_token});
	}

	clearToken() {
		console.log("clearToken");
		this.token$.next(null);
	}

	verifyPasswordChangeToken(token: string, email: string) {
		return this.http
			.post<any>(
				this.options.authServer + "/user/verifyPasswordChangeToken",
				{
					token,
					email,
				}
			)
			.pipe(
				map((res) => !!res),

				catchError((err) => {
					console.error("Failed to verifyPasswordChangeToken", err);
					return of(false);
				})
			);
	}

	changePassword(params: {
		password: string;
		email: string;
		token?: string;
		oldPassword?: string;
	}) {
		return this.http
			.post<any>(this.options.authServer + "/user/changePassword", params)
			.pipe(
				map(async (res) => {
					console.log("password change and login success!", res);
					this.setToken(res.accessToken);
					await this.saveTokens({token: res.accessToken, refresh_token: res.refreshToken});
					return !!res.token;
				}),

				catchError((err) => {
					console.error("Failed to verifyPasswordChangeToken", err);
					return of(false);
				})
			);
	}

	resetPassword(email: string) {
		return this.http
			.post<any>(this.options.apiServer + "/kiosk/resetPassword", {
				email,
			})
			.pipe(
				map((res) => {
					console.log("Password reset email sent!", res);
					return true;
				}),

				catchError((err) => {
					console.error("Failed to send reset email", err);
					return of(false);
				})
			);
	}

	deleteAccount({email, password}: {email: string, password: string}) {
		return this.http
			.post<any>(this.options.authServer + "/user/deleteAccount", {
				email,
				password
			})
			.pipe(
				map((res) => {
					console.log("Account deleted!", res);
					return true;
				}),
				catchError((err) => {
					console.error("Failed to delete account", err);
					return of(false);
				})
			);
	}
}
