import { ToastController } from "@ionic/angular";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class ToastService {
	constructor(private toastController: ToastController) {}

	async toast(message = "") {
		const toast = await this.toastController.create({
			message: message,
			duration: 3000,
			color: "primary",
			cssClass: "file-toast",
		});
		toast.present();
	}
}
