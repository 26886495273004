// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyAa49POa8uHG-_AFGM89ie77bqd2fVa5RY",
		authDomain: "dev-my-24-7-direct-app.firebaseapp.com",
		projectId: "dev-my-24-7-direct-app",
		storageBucket: "dev-my-24-7-direct-app.appspot.com",
		messagingSenderId: "811108390008",
		appId: "1:811108390008:web:8ab2a190bfaab5a535098d",
		measurementId: "G-365MMJ897K"
	},
	googleMaps: {
		apiKey: "AIzaSyDyfgNR82EWD9-uo5X7cZ2Id8a-EJxPAPs",
	},
	switchboardOptions: {
		// apiServer: "http://localhost:8080",
		// authServer: "http://localhost:8081",
		apiServer: "https://api-fccchq.fergdev.com",
		authServer: "https://admin-fccchq.fergdev.com",
	},
	sentry: {
		dsn: "https://4b3c94ad2c0f4325821a93a1444e3c67@app.glitchtip.com/6083",
		environment: "development", 
	},
	supportEmail: "rvfactoryservice@daimlertruck.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
