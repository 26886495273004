import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Http, HttpDownloadFileResult } from "@capacitor-community/http";
import { Capacitor } from "@capacitor/core";
import {
	Directory,
	Encoding,
	Filesystem,
	ReadFileResult,
	StatOptions
} from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { BehaviorSubject, forkJoin } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { Asset } from "src/app/services/types";
import { GuidesService } from "./guides.service";
import { ManualLibraryService } from "./manual-library.service";
import { Attachment } from "./types/types";

@Injectable({
	providedIn: "root",
	})
export class OfflineFilesService {
	private filesStates$: BehaviorSubject<Record<string, any>> =
		new BehaviorSubject({});

	constructor(
		private manualSvc: ManualLibraryService,
		private guidesSvc: GuidesService,
		private sanitizer: DomSanitizer,
		private platform: Platform
	) {}

	//https://medium.com/@k.eschua/tutorial-on-how-to-create-write-and-read-file-in-ionic-the-easy-way-d291081b5ad1
	public async getFile(
		path: string,
		filename: string
	): Promise<ReadFileResult> {
		//check if file exists
		try {
			const file = await Filesystem.readFile({
				path: `${path}/${filename}`,
				directory: Directory.Data,
				encoding: Encoding.UTF8,
			});
			return file;
		} catch (error) {
			// console.log(error);
		}
	}

	public async getOfflineFile(
		fileId: string,
		filename: string
	): Promise<ReadFileResult> {
		return await this.getFile(fileId, filename);
	}

	public async fileExists(options: StatOptions): Promise<boolean> {
		try {
			await Filesystem.stat(options);
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * If file is downloaded, get the URL. If the file is loading, wait until it's done, and get the URL.
	 * Otherwise, start the process of downloading and saving the file, and then get the URL.
	 * This should work fine for queued assets, because when they come up in the queue, they will already
	 * be marked as downloaded or loading.
	 */
	public getOfflineFileUrl(fileInfo: Attachment | Asset): Promise<string> {
		const fileState = this.getFilesStates$().value[fileInfo.id];
		if (fileState?.state == "downloaded") {
			return Promise.resolve(fileState.url);
		}

		if (fileState?.state != "loading") {
			this.saveFile(fileInfo);
		}

		return this.getFilesStates$()
			.pipe(
				map((states) => states[fileInfo.id]),
				tap((state) => {
					if (state.state == "failed")
						throw new Error(`File download ${fileInfo.id} failed`);
				}),
				filter((state) => state.state == "downloaded"),
				take(1),
				map((state) => state.url)
			)
			.toPromise();
	}

	public async saveFile(
		file: Attachment | Asset,
		path: string = null
		// whether: IWriteOptions = { replace: true },
	): Promise<any> {
		this.updateFileState(file.id, "loading");
		try {
			if (!path) path = file.id;

			const name = (file as Attachment).filename ?? (file as Asset).name;

			const options = {
				url: file.url.replace(/\s/g, "%20"),
				filePath: `${path}/${name}`,
				fileDirectory: Directory.Data,
				method: "GET",
			};

			try {
				await Filesystem.stat({ path, directory: Directory.Data });
			} catch (e) {
				await Filesystem.mkdir({ path, directory: Directory.Data });
			}

			// Writes to local filesystem
			const response: HttpDownloadFileResult = await Http.downloadFile(
				options
			);

			const fileOptions = {
				path: `${path}/${name}`,
				directory: Directory.Data,
			};
			const uri = (await Filesystem.getUri(fileOptions)).uri;

			this.updateFileState(
				file.id,
				"downloaded",
				Capacitor.convertFileSrc(uri),
				uri
			);

			return response;
		} catch (error) {
			//remove file
			// this.fileApi.removeFile(dir, fileId);
			//TODO try to save again?
			this.updateFileState(file.id, "failed");
			// if (tryCount < 3) {
			// this.saveFile(path, file);
			// }
			return false;
		}
	}

	// helper function to get all lists of files to make into offline files
	private getOfflineFileLists$() {
		const fileProps = ["thumbnail", "file"];
		const filterByAssetType = (record) =>
			record.assetType == "PDF" ||
			record.assetType == "IMAGE" ||
			record.assetType == "VIDEO";

		return forkJoin([
			this.manualSvc.getAllLibrary$().pipe(
				// skipWhile(val => !!val),
				map((library) => {
					// filter manuals by asset type, map manuals in shelves to attachments and flatten all shelves (now attachment arrays) into one large array of attachments
					return library.filter(filterByAssetType).reduce(
						(files, shelf) => [
							...files,
							...shelf.map((manual) => {
								return manual.file;
							}, []),
						],
						[]
					);
				}),
				take(1)
			),
			forkJoin([
				this.guidesSvc.getUserGuides$(),
				this.guidesSvc.getDashBoardIndicators$(),
				this.guidesSvc.getMaintenanceVideoSeries$()
			]).pipe(
				map((assets) => {
					return assets.flat(1);
				}),
				map((assets) => {
					return assets
						.filter(filterByAssetType)
						.map((asset) =>
							fileProps
								.filter((prop) => !!asset[prop])
								.map((prop) => asset[prop])
						)
						.reduce(
							(files, filesByPropsArr) => [
								...files,
								...filesByPropsArr,
							],
							[]
						)
						.filter(
							(file) => file != undefined && file.id != undefined
						);
				}),
				take(1),
			),
		]).pipe(
			map((fileLists) => {
				return fileLists.flat(1);
				// return [...fileLists[0], ...fileLists[1], ...fileLists[2]];
			}),
			take(1)
		);
	}

	//TODO rework this to download files based off of the fileState list (in fileState BehaviourSubject of getFileState)?
	// what this functions does
	// 1. get all files to save for offline use
	// 2. loop through all files
	//     3. initialize file state if not set yet (file state is our in memory map for if a file is downloaded or not. default state is "queued")
	//     4. if the file is already being downloaded, skip it
	//     5. if there are any other files being downloaded, wait for them to finish (concurrent downloads where making it so smaller files didn't load as fast because they shared bandwidth with larger files)
	//     6. check if the file exists in the filesystem
	//         7. if it does, update the file state to downloaded and include the path to the file in a format usable by src attribute
	//         8. if it doesn't, download the file and update the file state to downloaded and include the path to the file in a format usable by src attribute
	public async updateFiles(): Promise<void> {
		const allFilesToSaveToDevice =
			await this.getOfflineFileLists$().toPromise();

		for (const file of allFilesToSaveToDevice) {
			// If something else has already downloaded this or is currently loading it, skip it.
			const state = this.getFilesStates$().value[file.id]?.state;
			if (state == "downloaded" || state == "loading") {
				continue;
			} else if (state === undefined) {
				// if not directory.data than this should be the web version of the app so we don't need to download files
				// force file state to downloaded if not on mobile device (web app view)
				const state = !this.platform.is("hybrid") ? "downloaded" : "queued";
				// console.log(Directory.Data);
				this.updateFileState(file.id, state);
			}

			// TODO: maybe come up with a better way to do this, like a producer/consumer queue
			// Limit the number of concurrent downloads to 1.
			try {
				await this.getFilesStates$()
					.pipe(
						// See if a loading state exists anywhere
						map((states) =>
							Object.values(states).find(
								(fileState) => fileState.state == "loading"
							)
						),

						// Wait until there is NOT an existing loading state
						filter((loadingState) => !loadingState),
						take(1)
					)
					.toPromise();
			} catch (e) {
				// Fail silently
			}

			// download files if not already downloaded
			try {
				const fileOptions = {
					path: `${file.id}/${file.filename}`,
					directory: Directory.Data,
				};

				const exists = await this.fileExists(fileOptions);

				if (!exists) {
					await this.saveFile(file);
				} else {
					const uri = (await Filesystem.getUri(fileOptions)).uri;
					const fileSrcUrl = Capacitor.convertFileSrc(uri);
					this.updateFileState(file.id, "downloaded", fileSrcUrl, uri);
				}
			} catch (error) {
				await this.saveFile(file);
			}
		}
	}

	public getFilesStates$(): BehaviorSubject<Record<string, any>> {
		if (!this.filesStates$.value) {
			this.filesStates$.next({});
		}
		return this.filesStates$;
	}

	updateFileState(fileId: string, state: string, url?: string, filePath?: string) {
		const files = this.getFilesStates$().value;
		if (state) files[fileId] = { state };
		if (url)
			files[fileId]["url"] =
				this.sanitizer.bypassSecurityTrustUrl(url);
		if (filePath) files[fileId]["filePath"] = filePath;
		this.filesStates$.next(files);
	}
}
