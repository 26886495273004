import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { get } from "lodash/fp";
import { from, Observable } from "rxjs";
import {
	distinctUntilChanged,
	filter,
	map,
	skip,
	switchMap,
	take,
	tap,
} from "rxjs/operators";

import {
	defaultAssetCategoryFields,
	defaultAssetFields,
	defaultChassisFields,
	defaultLocationCategoryFields,
	defaultLocationFields,
	defaultOemFields,
	defaultSavedMapsFields,
	defaultUserFields,
	defaultWarrantyRecordFields,
	extendedUserFields
} from "./gql/fragments";
import { SwitchboardAuth } from "./switchboard-auth.service";

const queries = {
	favorites: ({ fields, extra }) => gql`
        query getFavorites {
            favorites {
                id
                ${fields || "...DefaultAssetFields"}
            }
        }

        ${defaultAssetFields}
        ${extra || ""}
    `,

	assetCategory: ({ fields, extra }) => gql`
        query getAssetCategory($key: String!) {
            assetCategory(key: $key) {
                key
                ${fields || "...DefaultAssetCategoryFields"}
            }
        }

        ${defaultAssetCategoryFields}
        ${extra || ""}
    `,
	currentUser: ({ fields, extra }) => gql`
        query getCurrentUser {
            currentUser {
                ${fields || "...DefaultUserFields"}
            }
        }

        ${defaultUserFields}
        ${extra || ""}
    `,

	currentUserProfile: ({ fields, extra }) => gql`
        query getCurrentUserProfile {
            currentUserProfile: currentUser {
                ...DefaultUserFields
                ${fields || "...ExtendedUserFields"}
            }
        }

        ${defaultUserFields}
        ${extendedUserFields}
        ${extra || ""}
    `,

	userByEmail: ({ fields, extra }) => gql`
        query getUserByEmail($email: String!) {
            userByEmail(email: $email) {
                ${fields || "...DefaultUserFields"}
            }
        }

        ${defaultUserFields}
        ${extra || ""}
    `,

	warrantyRecordByActivationCode: ({ fields, extra }) => gql`
        query getWarrantyRecordByActivationCode($activationCode: String!) {
            warrantyRecordByActivationCode(activationCode: $activationCode) {
                ${fields || "...DefaultWarrantyRecordFields"}
            }
        }

        ${defaultWarrantyRecordFields}
        ${extra || ""}
    `,

	// For sales-map
	locationCategories: ({ fields, extra }) => gql`
        query getLocationCategories {
            locationCategories {
                ${fields || "...DefaultLocationCategoryFields"}
            }
        }

        ${defaultLocationCategoryFields}
        ${extra || ""}
    `,
	locations: ({ fields, extra }) => gql`
        query getLocations($category: String!) {
            locations(category: $category) {
                ${fields || "...DefaultLocationFields"}
            }
        }

        ${defaultLocationFields}
        ${extra || ""}
    `,
	location: ({ fields, extra }) => gql`
        query getLocation($id: ID!) {
            location(id: $id) {
                ${fields || "...DefaultLocationFields"}
            }
        }

        ${defaultLocationFields}
        ${extra || ""}
    `,
	"location.stats": ({ fields, extra }) => gql`
        query getLocationStats($id: ID!) {
            location(id: $id) {
                id
                stats
                ${fields || ""}
            }
        }

        ${extra || ""}
    `,
	savedMaps: ({ fields, extra }) => gql`
        query getSavedMaps {
            savedMaps {
                _id
                ${fields || "...DefaultSavedMapsFields"}
            }
        }

        ${defaultSavedMapsFields}
        ${extra || ""}
    `,
	savedMap: ({ fields, extra }) => gql`
        query getSavedMap($id: ID!) {
            savedMap(_id: $id) {
                _id
                ${fields || "...DefaultSavedMapsFields"}
            }
        }

        ${defaultSavedMapsFields}
        ${extra || ""}
    `,

	allChassis: ({ fields, extra }) => gql`
        query getAllChassis {
            allChassis {
                ${fields || "...DefaultChassisFields"}
            }
        }

        ${defaultChassisFields}
        ${extra || ""}
    `,

	allOems: ({ fields, extra }) => gql`
        query getAllOems {
            allOems {
                ${fields || "...DefaultOemFields"}
            }
        }

        ${defaultOemFields}
        ${extra || ""}
    `,
	notifications: ({ fields, extra }) => gql`
		query notifications($dateRange: DateRange) {
			notifications(dateRange: $dateRange) {
				_id
				title
				body
			}
		}
	`
};

const mutations = {
	registerUser: gql`
		mutation registerUser($userInput: UserInput!, $activationCode: String) {
			registerUser(
				userInput: $userInput
				activationCode: $activationCode
			) {
				email
				firstName
				lastName
			}
		}
	`,

	updateUser: gql`
		mutation updateUser($userInput: UserInput!) {
			updateUser(userInput: $userInput) {
				# id
				email
				firstName
				lastName
				birthday
				phone
				notes {
					id
					text
					title
					createdDate
					lastModifiedDate
					relatedMaintenanceItem {
						id
						name
					}
				}
				ownership {
					vin
					make
					model
					year
					fcocMember
					fcocMemberSince
					engine {
						hp
						make
					}
					transmission {
						make
						fluidType
					}
					chassisManufactureDate
					purchaseDate
					mileageHistory {
						id
						date
						mileage
					}
					maintenanceHistory {
						id
						datePerformed
						actionId
						mileageRef
					}
				}
				primaryAddress {
					street
					city
					state
					zip
				}
			}
		}
	`,

	registerDeviceToken: gql`
		mutation registerDeviceToken($token: String!) {
			registerDeviceToken(token: $token) {
				token,
				expiresAt
			}
		}
	`,

	unregisterDeviceToken: gql`
		mutation unregisterDeviceToken($token: String!) {
			unregisterDeviceToken(token: $token)
		}
	`,

	updateUserWithActivationCode: gql`
		mutation updateUserWithActivationCode($activationCode: String!) {
			updateUserWithActivationCode(activationCode: $activationCode) {
				email
				firstName
				lastName
				birthday
				phone
				ownership {
					vin
					make
					model
					year
					fcocMember
					fcocMemberSince
				}
				primaryAddress {
					street
					city
					state
					zip
				}
			}
		}
	`,

	addFavorite: gql`
		mutation addFavorite($id: String!) {
			addFavorite(id: $id) {
				id
				assetType
				name
				url
				file {
					url
				}
				thumbnail {
					url
				}
			}
		}
	`,
	removeFavorite: gql`
		mutation removeFavorite($id: String!) {
			removeFavorite(id: $id) {
				id
				assetType
				name
				url
				file {
					url
				}
				thumbnail {
					url
				}
			}
		}
	`,

	removeNote: gql`
		mutation removeNote($id: String!) {
			removeNote(id: $id) {
				id
				email
				notes {
					id
				}
			}
		}
	`,
	addNote: gql`
		mutation addNote($note: AddNoteInput!) {
			addNote(note: $note) {
				id
				email
				notes {
					id
				}
			}
		}
	`,
	updateNote: gql`
		mutation updateNote($note: UpdateNoteInput!) {
			updateNote(note: $note) {
				id
				email
				notes {
					id
				}
			}
		}
	`,

	removeMileage: gql`
		mutation removeMileage($id: String!) {
			removeMileage(id: $id) {
				id
				email
				ownership {
					mileageHistory {
						id
					}
				}
			}
		}
	`,

	removeMaintenanceRecord: gql`
		mutation removeMaintenanceRecord($id: String!) {
			removeMaintenanceRecord(id: $id) {
				id
				email
				ownership {
					maintenanceHistory {
						id
					}
				}
			}
		}
	`,

	updateLocation: gql`
		mutation updateLocation($location: LocationInput) {
			updateLocation(location: $location) {
				id
				name
				category
				tags {
					name
					image {
						url
					}
				}
				contact {
					firstName
					lastName
					phone
					email
					role
				}
				coordinates {
					latitude
					longitude
				}
				address {
					street
					state
					city
					zip
					country
				}
			}
		}
	`,
	createSavedMap: gql`
		mutation createSavedMap($input: SavedMapInput) {
			createSavedMap(input: $input) {
				_id
				name
				date
				filters
			}
		}
	`,
	updateSavedMap: gql`
		mutation updateSavedMap($id: ID!, $input: SavedMapInput) {
			updateSavedMap(id: $id, input: $input) {
				_id
				name
				date
				filters
			}
		}
	`,
	removeSavedMap: gql`
		mutation removeSavedMap($id: ID!) {
			removeSavedMap(_id: $id) {
				_id
				name
				date
				filters
			}
		}
	`,
	requestMaintenanceService: gql`
		mutation requestMaintenanceService($input: MaintenanceServiceRequestInput) {
			requestMaintenanceService(input: $input) {
				type
				message
			}
		}
	`,
	logAppEvent: gql`
		mutation logAppEvent($action: String!, $date: String!) {
			logAppEvent(action: $action, date: $date)
		}
	`,
	markNotificationAsRead: gql`
		mutation markNotificationAsRead($id: String!) {
			markNotificationAsRead(id: $id)
		}
	`,
};

@Injectable({
	providedIn: "root",
	})
export class SwitchboardClient {
	private ready: Promise<boolean> = Promise.resolve(true);

	constructor(private apollo: Apollo, private auth: SwitchboardAuth) {
		this.auth.token$
			.pipe(skip(1), distinctUntilChanged())
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.subscribe((_) => {
				console.log("resetting Apollo");

				this.ready = this.apollo.client
					.resetStore()
					.then((result) => true);
			});
	}

	query<T = any>(
		queryName: string,
		variables?: any,
		fetchPolicy?: any
	): Observable<T> {
		if (!queries[queryName]) {
			console.error("GraphQL query not found");
			return;
		}
		fetchPolicy = fetchPolicy ? fetchPolicy : "cache-and-network";
		const query$ = this.apollo
			.watchQuery<any>({
				query: queries[queryName]({}),
				variables,
				fetchPolicy,
			})
			.valueChanges.pipe(
				filter(({ loading }) => !loading),

				map(({ data }) => get(queryName)(data))
			);

		// Defer query until Apollo is ready
		return from(this.ready).pipe(switchMap((ready) => query$));
	}

	queryOnce<T = any>(
		queryName: string,
		variables?: any,
		fetchPolicy?: any
	): Promise<T> {
		if (!queries[queryName]) return;

		return this.query<T>(queryName, variables, fetchPolicy)
			.pipe(take(1))
			.toPromise();
	}

	mutate(mutationName: string, variables?: any) {
		if (!mutations[mutationName])
			throw new Error("GraphQL mutation not found");
		const query = mutations[mutationName];

		const mutation$ = this.apollo.mutate({
			mutation: query,
			variables,

			// optimisticResponse: {
			//     __typename: 'Mutation',
			//     query: {
			//         id: id
			//     }
			// }
		});
		// Defer mutation until Apollo is ready
		return from(this.ready).pipe(switchMap((ready) => mutation$));
	}
}
