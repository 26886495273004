import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FCM } from "@capacitor-community/fcm";
import { App, AppState } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map, mergeMap, take } from "rxjs/operators";
import { SwitchboardAuth } from "./switchboard-auth.service";
import { SwitchboardClient } from "./switchboard-client.service";
import { UserService } from "./user.service";

export type GeneralNotificationData = {
	type: "general";
	id: string;
};
export type MaintenanceNotificationData = {
	type: "maintenance";
};
// overrides "data: any" with a more specific type based on the .type field (otherwise known as amazing DX :D )
export type PushNotification = Omit<PushNotificationSchema, "data"> & {
	data: GeneralNotificationData | MaintenanceNotificationData;
}

// these types represent the data stored in the database when a notification is created
type RawNotificationEntry = {
	_id: string;
	title?: string;
	body?: string;
	scheduledFor?: string;
}
export type NotificationEntry = RawNotificationEntry & {
	read: boolean;
};

@Injectable({
	providedIn: 'root'
	})
export class NotificationsService {
	// constants
	private static readonly MAX_RETRY_COUNT = 1;

	// notifications
	public notifications$: Observable<NotificationEntry[]>;
	public unreadNotificationCount$: Observable<number>;
	public showDesktopNotifications$ = new BehaviorSubject<boolean>(false);
	private refreshNotifications$ = new BehaviorSubject<boolean>(true);

	private token$ = new BehaviorSubject<string>(null);

	// registration state
	private retryCount = 0;

	constructor(private userSvc: UserService, private router: Router, private swbSvc: SwitchboardClient, private auth: SwitchboardAuth) {
		// add push notification listeners
		if (Capacitor.isNativePlatform()) {
			PushNotifications.addListener("registration", this.handleRegistration.bind(this));
			PushNotifications.addListener("registrationError", this.handleRegistrationError.bind(this));
			PushNotifications.addListener("pushNotificationActionPerformed", this.handleNotificationRouting.bind(this));
			PushNotifications.addListener("pushNotificationReceived", () => this.refreshNotifications$.next(true)); // if we receive a push notification while in app, force refresh notifications data
		} else if (Capacitor.DEBUG) {
			// debugging for registering token endpoint
			// TODO: remove or comment out at the end of development
			const token = "edRIhaqrvU1OtE9briJ14R:APA91bEHh2ze3mrUWQ0_NJk1cTLO4ZVa7NdC5dLZHJiVVRGgYx0L_eD_g3w4RLnIh-IPyfhIvp8VVY-33izQAyN0-QuuOdU7t9U0eg98XZyaBGFjwEIeYPb37OfDUNNIVyM_wlfkm9qZ";
			this.userSvc.registerDeviceToken(token).then(() => {
				this.userSvc.unregisterDeviceToken(token);
			});
		}

		// automatically register push notifications when user logs in and unregister when user logs out
		auth.isAuthenticated$.subscribe(authenticated => {
			if (authenticated) {
				this.registerPushNotifications();
			}
		});

		App.addListener("appStateChange", (state: AppState) => {
			if (state.isActive) {
				this.refreshNotifications$.next(true);
			}
		});

		// fetch notifications when user logins, when the app resumes, or when we receive a push notification
		this.notifications$ = combineLatest([
			this.userSvc.getUser$(), // on login
			this.refreshNotifications$, // on refresh
		]).pipe(
			filter(([user, refresh]) => !!user && refresh),
			mergeMap(([user, refresh]) =>
				combineLatest(
					[
						swbSvc.queryOnce("notifications", { dateRange: { end: new Date().toISOString()}})
					]
				).pipe(
					map(notifications => notifications.reduce((acc, val) => acc.concat(val), [])), // flatten array
					map(notifications => notifications.map(notification => ({
						...notification,
						read: (user.readNotifications ?? []).includes(notification._id),
					}))),
				)
			),
		);

		this.unreadNotificationCount$ = this.notifications$.pipe(
			map(notifications => notifications.filter(notification => !notification.read).length),
		);
	}

	// do this after logged in
	async registerPushNotifications() {
		if (!Capacitor.isNativePlatform()) return;
		// added this for android 13 - requires a checkPermissions to be run first according to capacitor docs
		let permStatus = await PushNotifications.checkPermissions();
		
		if (permStatus.receive === 'prompt') {
			permStatus = await PushNotifications.requestPermissions();
		}
		
		if (permStatus.receive !== 'granted') {
			console.log("User denied Permission")
			return;
		}		
		await PushNotifications.register();

	}

	// do this on logout
	async unregisterPushNotifications() {
		if (!Capacitor.isNativePlatform()) return;
		FCM.unsubscribeFrom({ topic: "general" });
		if (this.token$.value) {
			await this.userSvc.unregisterDeviceToken(this.token$.value);
			this.token$.next(null);
		}
		PushNotifications.unregister();

	}

	// handle successful registration
	private async handleRegistration(token: Token) {
		// return early if token$ is already set to avoid creating new deviceTokens in switchboard with separate registration events (onTokenChange and 1st registration are firing at the same time)
		if (token.value === this.token$.value) return;
		this.token$.next(token.value);
		try {
			this.userSvc.registerDeviceToken(token.value)
			.then(() => {
				console.log("Registered device token");
			})
			.catch(error => {
				console.warn("Error registering device token");
				console.error(error);
			});
		} catch(e) {
			console.warn("Error registering device token");
			console.error(e);
		}
		try {
			FCM.subscribeTo({ topic: "general" }).then(() => {
				console.log("Subscribed to general notifications");
			}).catch(error => {
				console.warn("Error subscribing to general notifications");
				console.error(error);
			});
		} catch(e) {
			console.warn("Error subscribing to general notifications");
			console.error(e);
		}
	}

	// handle failed registration
	private handleRegistrationError(error: any) {
		console.warn("Error during registration: " + JSON.stringify(error));

		if (this.retryCount < NotificationsService.MAX_RETRY_COUNT) {
			// TODO: display toast message to user and timeout before retrying
			this.retryCount++;
			this.registerPushNotifications();
		} else {
			// TODO: display toast message to user??
		}
	}

	// handle when user clicks notification outside of app
	private handleNotificationRouting(action: ActionPerformed) {
		const notification: PushNotification = action.notification;
		switch (notification.data.type) {
			case "maintenance":
				this.router.navigateByUrl("/main/maintenance");
				break;
			case "general":
				this.markRead(notification.data.id);
				this.router.navigateByUrl(`/main/notifications/${notification.data.id}`);
				break;
			default:
				alert(`${notification?.title}\n${notification.notification?.body}`);
		}
	}

	public async markAllRead() {
		const notifications = await this.notifications$.pipe(take(1)).toPromise();
		const unread = notifications.filter(notification => !notification.read);
		await Promise.all(unread.map(notification => this.userSvc.markNotificationAsRead(notification._id)));

		await this.userSvc.refetchUser();
	}

	public async markRead(id: string) {
		await this.userSvc.markNotificationAsRead(id);

		this.unreadNotificationCount$.pipe(
			filter(count => count > 0),
			take(1),
		).subscribe(() => this.userSvc.refetchUser());
	}

	public toggleDesktopNotifications() {
		this.showDesktopNotifications$.next(!this.showDesktopNotifications$.value);
	}

	public hideDesktopNotifications() {
		this.showDesktopNotifications$.next(false);
	}
}
