import lf from "localforage";
import { take } from "rxjs/operators";
import { NetworkService, NetworkType } from "../network.service";

const VERBOSE = false;

// const connection = navigator['connection'];
// export const isOnline = () => navigator.onLine;

// TODO cleanup of old, unusued key/value pairs

export function createKey(...parts): string {
	return parts
		.map((part) => (typeof part == "string" ? part : JSON.stringify(part)))
		.join("::");
}

export async function persistAsync<T = any>(
	key: string,
	source: Promise<T>,
	netsvc: NetworkService,
	transformPut?: (dirtyData: T) => any,
	transformGet?: (cleanData: any) => T
): Promise<T> {
	let data: T;
	let dataIsFresh = false;

	const isOnline = netsvc.online.value;

	try {
		if (isOnline === false) throw new Error("offline");
		data = await source;
		dataIsFresh = true;
	} catch (err) {
		// If it's anything other than just being offline, log it
		if (VERBOSE || err.message != "offline")
			console.warn("error getting", key, "data");
	}

	// If we couldn't get data, fall back to localforage
	if (!data) {
		data = await getFromStorage(key, transformGet);
	}

	// If we actually got the data from the server, then we store it in localforage
	// P.S. No need to await here, because that would just slow down the return of the data
	if (data && dataIsFresh) putInStorage(key, data, transformPut);
	return data;
}

async function getFromStorage<T>(
	key: string,
	transformGet?: (cleanData: any) => T
): Promise<T> {
	let data: T = null;

	try {
		const rawData = await lf.getItem<any>(key, (err, value) => {
			console.log(err, value);
		});
		if (transformGet) data = transformGet(rawData);
		else data = await lf.getItem<T>(key);

		if (VERBOSE) console.log("got from storage", key);
	} catch (err) {
		console.warn("localforage error:", err);
	}

	return data;
}

function putInStorage(
	key: string,
	data: any,
	transformPut?: (dirtyData) => any
) {
	if (VERBOSE) console.log("storing", key);
	if (transformPut) data = transformPut(data);
	return lf.setItem(key, data);
}
