import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Preferences } from "@capacitor/preferences";
import { SplashScreen } from "@capacitor/splash-screen";
import { BehaviorSubject, from, Observable, of, ReplaySubject } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from "rxjs/operators";
import { NotificationsService } from "src/app/services/notifications.service";
import { createKey, persistAsync } from "./data/persistence";
import { checkIfLoginPath } from "./guards/route-helper";
import { NetworkService } from "./network.service";
import { SwitchboardAuth } from "./switchboard-auth.service";
import { SwitchboardClient } from "./switchboard-client.service";
import { ToastService } from "./toast.service";
import { User } from "./types/types";

@Injectable({
	providedIn: "root",
	})
export class UserService {
	private user$: BehaviorSubject<User>;
	// private authenticated$: BehaviorSubject
	private state$: BehaviorSubject<any> = new BehaviorSubject({});

	constructor(
		private auth: SwitchboardAuth,
		private sb: SwitchboardClient,
		private netSvc: NetworkService,
		private toaster: ToastService,
		private router: Router,
		private injector: Injector,
	) { }

	getUser$() {
		if (!this.user$) {
			this.user$ = new BehaviorSubject<User | null>(null);
			this.auth.token$.pipe(
				distinctUntilChanged(),
				switchMap((token) => {
					if (token) {
						return this.getUserProfile$().pipe(filter(user => !!user));
					} else {
						return of(null);
					}
				}),
			).subscribe(user => {
				this.user$.next(user);
			});
		}
		return this.user$;
	}
	getUserName$() {
		return this.getUser$().pipe(
			map((user) => {
				if (user) {
					return user.firstName + " " + user.lastName;
				} else {
					return "";
				}
			})
		);
	}

	isUserCampFGrad$() {
		return this.getUser$().pipe(
			map((user) => {
				return user.permissions.includes(
					"Role:CampFreightlinerIGraduate"
				);
			})
		);
	}

	getUserGraduationDateCFI$() {
		return this.getUserProfile$().pipe(
			map((user) => {
				if (user) {
					return user.graduationDateCFI;
				} else {
					return "";
				}
			})
		);
	}

	getUserProfile$(): Observable<User> {
		return this.queryCurrentUser();
	}

	refreshUser(user: User) {
		return this.user$.next(user);
	}

	async refetchUser() {
		this.user$.next(await this.queryCurrentUser().pipe(take(1)).toPromise());
	}

	private queryCurrentUser(): Observable<User> {
		return from(
			persistAsync(
				createKey("user"),
				this.sb
					.query("currentUserProfile")
					.pipe(
						take(1),
						catchError((err) => {
							console.error(
								"Error getting current user details:",
								err
							);
							return of(null);
						})
					)
					.toPromise(),
				this.netSvc
			)
		);
	}

	needsLogin$() {
		return this.getUser$().pipe(map((user) => !user));
	}

	login(email: string, password: string) {
		return this.auth.login(email, password).pipe(
			switchMap((success) => {
				if (success) {
					return this.queryCurrentUser();
				} else {
					return of(null);
				}
			}),
			tap((user) => {
				return this.user$.next(user);
			}),
			map((user) => !!user),

			take(1)
		);
	}

	async logout() {
		this.auth.isLoggingOut = true;
		SplashScreen.show();
		this.auth.isAuthenticated$.next(false);
		const notifications = this.injector.get(NotificationsService);
		await notifications.unregisterPushNotifications();
		if (this.auth.getToken()) this.auth.clearToken();
		await Preferences.clear();
		this.user$.next(null);
		if (!checkIfLoginPath(this.router.url)) return this.router.navigate(["/login"])
		.then(() => {
			SplashScreen.hide();
			this.toaster.toast("You have been logged out.");
			this.auth.isLoggingOut = false;
		});
		return () => {
			SplashScreen.hide();
			this.toaster.toast("You have been logged out.");
			this.auth.isLoggingOut = false;
		};


	}

	deleteAccount({email, password}: {email: string, password: string}): Observable<boolean> {
		//get user email
		return this.getUser$().pipe(
			map((user) => {
				if (user && user.email === email) {
					return user.email;
				} else {
					return "";
				}
			}),
			switchMap(email => {
				return this.auth.deleteAccount({email, password}).pipe(
					map((success) => {
						if (success) {
							return true;
						} else {
							return false;
						}
					}),
					catchError(err => {
						console.error("Error deleting account:", err);
						return of(false);
					})
				);
			}),
			take(1)
		);

		//check if email matches
	}

	register(userInput: any, activationCode?: string) {
		return this.sb
			.mutate("registerUser", { userInput, activationCode })
			.toPromise();
	}

	async updateUserProfile(userInput: any): Promise<any> {
		if (!userInput) return false;
		const result = await this.sb
			.mutate("updateUser", { userInput })
			.pipe(
				switchMap((success) => {
					if (success) {
						return this.queryCurrentUser();
					} else {
						return of(null);
					}
				}),
				tap((user) => {
					return this.user$.next(user);
				}),
				take(1)
			)
			.toPromise();
		return result;
	}

	async checkActivationCode(
		activationCode: string
	): Promise<{ codeIsValid: boolean; registeredCustomerName: string }> {
		const [activationResult] = await Promise.all([
			this.sb.queryOnce("warrantyRecordByActivationCode", {
				activationCode,
			}),
		]);
		const codeIsValid =
			!!activationResult && !!activationResult.activationCode;
		const registeredCustomerName =
			activationResult && activationResult.registeredCustomerName;

		return {
			codeIsValid,
			registeredCustomerName,
		};
	}

	async activateExistingUser(activationCode: string) {
		const activationResult = await this.sb
			.mutate("updateUserWithActivationCode", { activationCode })
			.toPromise();
		return !!activationResult;
	}

	async checkUserExists(email: string) {
		const userResult = await this.sb.queryOnce("userByEmail", { email });

		return !!userResult && !!userResult.email;
	}

	changePassword(params: {
		password: string;
		email: string;
		token?: string;
		oldPassword?: string;
	}) {
		// this.auth.clearToken();

		return this.auth.changePassword(params).pipe(
			switchMap((success) => {
				if (success) {
					return this.queryCurrentUser();
				} else {
					return of(null);
				}
			}),
			tap((user) => {
				if (user) this.user$.next(user);
			}),
			map((user) => !!user),

			take(1)
		);
	}

	resetPassword(email: string) {
		return this.auth.resetPassword(email);
	}

	hasBeenWelcomed$() {
		return this.getUser$().pipe(
			map((user) => {
				return !!(
					user &&
					user.ownership?.vin &&
					user.ownership?.chassisManufactureDate &&
					user.ownership?.engine?.hp &&
					user.ownership?.engine?.make &&
					user.ownership?.transmission?.make
				);
			})
		);
	}
	isDemoMode$() {
		return this.getUser$().pipe(
			filter((user) => !!user),
			map((user) => {
				return user.ownership?.vin == "demo";
			})
		);
	}

	logAppOpened() {
		// if user is not logged in, return early
		if (!this.auth.getToken()) return;
		try {
			return this.sb.mutate("logAppEvent", { action: "opened", date: new Date().toISOString() }).toPromise();
		} catch (e) {
			console.log(e);
		}
	}

	logSwitchboardEvent({ action, date = new Date().toISOString() }: { action: string; date?: string }) {
		try {
			return this.sb.mutate("logAppEvent", { action, date }).toPromise();
		} catch (e) {
			console.log(e);
		}
	}

	registerDeviceToken(token: string) {
		return this.sb.mutate("registerDeviceToken", { token }).toPromise();
	}

	unregisterDeviceToken(token: string) {
		if (!this.auth.getToken()) return;
		return this.sb.mutate("unregisterDeviceToken", { token }).toPromise();
	}

	markNotificationAsRead(id: string) {
		return this.sb.mutate("markNotificationAsRead", { id }).toPromise();
	}
}
