import { NotificationsService } from "./services/notifications.service";
import { SplashScreen } from "@capacitor/splash-screen";
import { LocationsService } from "src/app/services/locations.service";
import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { PreloadService } from "src/app/services/preload.service";
import { UserService } from "./services/user.service";
import { take } from "rxjs/operators";
import { StatusBar, Style } from "@capacitor/status-bar";
import { AnalyticsService } from "./services/analytics.service";
import { PushNotifications } from "@capacitor/push-notifications";
import { TextZoom } from "@capacitor/text-zoom";
import { SwitchboardAuth } from "./services/switchboard-auth.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
	})
export class AppComponent {
	constructor(
		private preloadService: PreloadService,
		private userService: UserService,
		private locationService: LocationsService,
		private platform: Platform,
		private analyticsService: AnalyticsService,
		private auth: SwitchboardAuth,
	) {
		this.initializeApp();
		this.analyticsService.logEvent({name: "app_opened"});
		// save in user analytics -> date app opened
		this.userService.logAppOpened();
	}

	initializeApp() {
		
		if (this.platform.is("hybrid")) {
			StatusBar.setStyle({ style: Style.Light });
		}
		this.platform.ready().then(async () => {
			if (this.platform.is("hybrid")) {
				TextZoom.set({value: 1});
			}
			
			this.preloadService.preload();
			// preload user and service locations on app load
			if (!this.auth.getToken()) {
				return SplashScreen.hide();
			}
			this.locationService
				.getCurrentCoordinates()
				.then((coords) => {
					return Promise.all([
						this.userService.getUser$().pipe(take(1)).toPromise(),
						this.locationService
							.getServiceLocationsByDistance$(
								coords.lat,
								coords.lon
							)
							.pipe(take(1))
							.toPromise(),
					]);
				})
				.then(() => {
					SplashScreen.hide();
				});
		});
	}
}
