import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Platform } from "@ionic/angular";
import md5 from "md5";
import { filter } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import {
	AppTrackingTransparency,
	AppTrackingStatusResponse,
} from "capacitor-plugin-app-tracking-transparency";

@Injectable({
	providedIn: "root"
})
export class AnalyticsService {
	analyticsEnabled = true;
	initialized: Promise<any>;

	constructor( private router: Router, public userSvc: UserService, public platform: Platform ) {
		this.initialized = this.initFb();
		this.initial();
	}


	async initFb() {

		if (!this.platform.is("hybrid")) {
			return FirebaseAnalytics.initializeFirebase(environment.firebase);
		}
		return new Promise(resolve => resolve(true));

	}

	async initial() {
		if (this.platform.is("ios")) {
			const status = await this.getStatus();
			if (status.status === "denied") return this.setAnalyticsCollectionEnabled(false);
		}
		this.router.events.pipe(
			filter((e: RouterEvent) => e instanceof NavigationEnd),
		).subscribe((e: RouterEvent) => {
			if (e.url === "/main" || e.url === "/") return this.setScreenName("/main/home");
			this.setScreenName(e.url);
		});
		this.setUser();
	}

	async setUser() {
		const status = await this.getStatus();
		if (status.status === "denied") return;
		// Use Firebase Auth uid
		this.userSvc.getUser$().pipe(filter(user => !!user)).subscribe(async user => {
			await this.initialized;
			FirebaseAnalytics.setUserId({
				userId: md5(user.email)
			});
		});
	}

	setProperty() {
		FirebaseAnalytics.setUserProperty({
			name: "framework",
			value: "angular",
		});
	}

	async logEvent({name, params = {}}: {name: string, params?: Record<string, any>}) {
		try {
			const status = await this.getStatus();
			if (status.status === "denied") return;
			await this.initialized;
			FirebaseAnalytics.logEvent({
				name,
				params
			});
		} catch (e) {
			console.log(e);
		}
	}

	async setScreenName(screenName: string) {
		const status = await this.getStatus();
		if (status.status === "denied") return;
		FirebaseAnalytics.setScreenName({
			screenName,
			nameOverride: screenName
		});
	}

	toggleAnalytics() {
		this.analyticsEnabled = !this.analyticsEnabled;
		FirebaseAnalytics.setCollectionEnabled({
			enabled: this.analyticsEnabled,
		});
	}

	setAnalyticsCollectionEnabled(state = true) {
		this.analyticsEnabled = state;
		FirebaseAnalytics.setCollectionEnabled({
			enabled: this.analyticsEnabled,
		});
	}

	public async getStatus(): Promise<AppTrackingStatusResponse> {
		if (this.platform.is("ios")) {
			const response = await AppTrackingTransparency.getStatus();
			if (response.status === "notDetermined") {
				// request permission
				const permission = this.requestPermission();
				console.log(permission);
				return permission;
			}
			return response;
		} else {
			return {
				status: "authorized"
			};
		}
	}

	public async requestPermission(): Promise<AppTrackingStatusResponse> {
		const response = await AppTrackingTransparency.requestPermission();
		return response;
	}
}
