import { Injectable } from "@angular/core";
import { Network } from "@capacitor/network";
import { Platform } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";

/**
 * @ref https://github.com/apache/cordova-plugin-network-information
 */
export enum NetworkType {
	unknown = "Unknown connection",
	wifi = "WiFi connection",
	cell = "Cell generic connection",
	none = "No network connection",
}

@Injectable({
	providedIn: "root",
})
export class NetworkService {
	public online: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public type: BehaviorSubject<string>;

	constructor(private platform: Platform) {
		this.type = new BehaviorSubject("unknown");

		this.platform.ready().then(() => {
			this.initNetworkEvents();
		});
	}

	/**
	 * @ref https://ionicframework.com/docs/native/network
	 */
	private async initNetworkEvents() {
		// console.log("initNetworkEvents");
		const status = await Network.getStatus();
		if (status.connected) {
			this.type.next(status.connectionType);
			this.online.next(true);
		} else {
			this.online.next(false);
		}
		Network.addListener("networkStatusChange", (status) => {
			if (status.connected) {
				this.type.next(status.connectionType);
				this.online.next(true);
			} else {
				this.online.next(false);
			}
		});

		// if (this.network.type) this.type.next(NetworkType[this.network.type]);
	}
}
