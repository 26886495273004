import { Injectable } from "@angular/core";
import { Geolocation } from "@capacitor/geolocation";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { catchError, map, shareReplay, switchMap, take } from "rxjs/operators";

import { createKey, persistAsync } from "./data/persistence";
import { NetworkService } from "./network.service";
import { SwitchboardClient } from "./switchboard-client.service";
import { ChosenPlace, Coordinates, ServiceFilter, ServiceLocationData } from "./types";

const COORDINATES_EXPIRE_AFTER = 15 * 60000;
const DEFAULT_COORDINATES: Coordinates = { lat: 35.10259, lon: -81.67932 }; //fort wayne

@Injectable({
	providedIn: "root",
	})
export class LocationsService {
	private ServiceLocations$: Observable<ServiceLocationData[]>;
	private indexedLocations$: Observable<any>;

	private filterList$: BehaviorSubject<any>;
	private filtersVisible$: BehaviorSubject<boolean>;

	private lastKnownCoordinates: Coordinates;
	private coordinatesLastUpdated: number;

	public chosenPlace$: BehaviorSubject<ChosenPlace>;


	private cachedOrderedLocations: Observable<{ distance: number; location: ServiceLocationData }[]>;
	private cachedOrigin: Coordinates | undefined;

	constructor(
		private apollo: Apollo,
		private sb: SwitchboardClient,
		private netsvc: NetworkService
	) {
		this.lastKnownCoordinates = JSON.parse(localStorage.getItem("locations:lastKnownCoordinates")) || DEFAULT_COORDINATES;
		this.coordinatesLastUpdated = parseInt(localStorage.getItem("locations:coordinatesLastUpdated")) || 0;
	}
	setupLocations() {
		this.getServiceLocations$();
	}

	getChosenPlace$() {
		if (!this.chosenPlace$) {
			this.chosenPlace$ = new BehaviorSubject({
				coordinates: this.lastKnownCoordinates,
				name: "Current Location",
			});
		}
		return this.chosenPlace$;
	}

	getSwitchboardLocations() {
		return from(
			persistAsync(
				createKey("locations"),
				this.sb
					.query("locations", { category: "service" })
					.pipe(
						take(1),
						map((locations: any) => {
							const output = [];
							for (const location of locations) {
								const outputLocation = {
									...location,
									lat: location.coordinates.latitude,
									lon: location.coordinates.longitude,
									...location.address,
								};
								output.push(outputLocation);
							}
							return output;
						}),
						catchError((err) => {
							console.error(err);
							return of(null);
						})
					)
					.toPromise(),
				this.netsvc
				// data => data.map(raw => new Record(null, null, raw))
			)
		);
	}
	async getSwitchboardLocation(id: string) {
		const location = this.apollo
			.watchQuery<any>({
				query: gql` {
					location(id: "${id}"){
						id
						name
						phone
						tollFreePhone
						wreckerPhone
						fax
						oasis
						eliteSupport
						specialServices
						coordinates {
							latitude
							longitude
						}
						address {
							street
							state
							city
							zip
							country
						}
						serviceHours {
							monday {
								open
								close
							}
							tuesday {
								open
								close
							}
							wednesday {
								open
								close
							}
							thursday {
								open
								close
							}
							friday {
								open
								close
							}
							saturday {
								open
								close
							}
							sunday {
								open
								close
							}
						}
					}
				}
			`,
			})
			.valueChanges.pipe(
				take(1),
				map(({ data }) => data.location)
			);
		return location;
	}

	getServiceLocations$() {
		if (!this.ServiceLocations$) {
			// check filters and filter to that.
			this.ServiceLocations$ = combineLatest([this.getSwitchboardLocations(), this.getActiveFilters$()]).pipe(
				map(([locations, filters]) => {
					return locations.filter((location) => {
						return filters.every((filter) => location[filter.id]);
					});
				})
			);
		}
		return this.ServiceLocations$;
	}

	private createIndexedLocations() {
		this.indexedLocations$ = this.getServiceLocations$().pipe(
			take(1),
			map((locations) => {
				const output = {};

				locations.forEach((location) => {
					output[location.id] = location;
				});

				return output;
			}),

			// Creates a ReplaySubject behind the scenes to share the data with all subscribers
			shareReplay(1)
		);
	}

	getIndexedLocations$() {
		if (!this.indexedLocations$) {
			this.createIndexedLocations();
		}
		return this.indexedLocations$;
	}

	getLocation(locationId) {
		return this.getIndexedLocations$().pipe(
			map((locations) => {
				if (locations[locationId]) {
					return locations[locationId];
				}
				return null;
			})
		);
	}

	initFilters() {
		this.filterList$ = new BehaviorSubject(null);
	}

	getFilters$() {
		if (!this.filterList$) this.initFilters();
		return this.filterList$;
	}

	getActiveFilters$(): Observable<ServiceFilter[]> {
		return this.getFilters$().pipe(
			// Convert filters object into an array of ServiceFilters.
			map((filters) =>
				// Set the id of the ServiceFilter using the Record keys.
				Object.keys(filters || {})
					.map<ServiceFilter>((key) => {
						filters[key].id = key;
						return filters[key];
					})
					.filter(filter => filter.active)
			)
		);
	}

	setFilter(key: string, value: ServiceFilter) {
		if (!this.filterList$) this.initFilters();
		const filters = this.getFilters$().value;
		filters[key] = value;
		this.filterList$.next(filters);
	}

	clearFilters() {
		if (!this.filterList$) this.initFilters();
		this.filterList$.next(null);
	}

	clearFilter(key) {
		if (!this.filterList$) this.initFilters();
		const filters = this.getFilters$().value;
		delete filters[key];
		this.filterList$.next(filters);
	}

	setFilters(filters) {
		if (!this.filterList$) this.initFilters();
		this.filterList$.next(filters);
	}

	setFiltersVisible(boolean: boolean) {
		if (!this.filtersVisible$) this.filtersVisible$ = new BehaviorSubject(false);
		this.filtersVisible$.next(boolean);
	}

	getFiltersVisible$() {
		if (!this.filtersVisible$) this.filtersVisible$ = new BehaviorSubject(false);
		return this.filtersVisible$;
	}

	// use geolocation to get user's device coordinates
	public async getCurrentCoordinates(): Promise<Coordinates> {
		//TODO: Fix on mobile. Right now nothing is returned so this time out function is a bad fix

		if (
			this.lastKnownCoordinates &&
			Date.now() - this.coordinatesLastUpdated < COORDINATES_EXPIRE_AFTER
		) {
			// console.log(
			// 	"using recent lastKnownCoordinates:",
			// 	this.lastKnownCoordinates
			// );
			return this.lastKnownCoordinates;
		}

		const timeoutPromise = new Promise<Coordinates>((_, reject) => {
			setTimeout(() => {
				reject("Request for location timed out!");
			}, 10000);
		});

		const locationPromise = Geolocation.getCurrentPosition().then(
			(resp) =>
				<Coordinates>{
					lat: resp.coords.latitude,
					lon: resp.coords.longitude,
				}
		);

		try {
			const coords = await Promise.race([
				locationPromise,
				timeoutPromise,
			]);
			this.setLastKnownCoordinates(coords);
			// console.log("got real current location", coords);

			return coords;
		} catch (err) {
			console.warn("Error getting location:", err);
			console.warn(
				"Falling back to lastKnownCoordinates:",
				this.lastKnownCoordinates
			);
			return this.lastKnownCoordinates;
		}
	}

	private setLastKnownCoordinates(coords: Coordinates) {
		this.lastKnownCoordinates = coords;
		this.coordinatesLastUpdated = Date.now();

		localStorage.setItem(
			"locations:lastKnownCoordinates",
			JSON.stringify(coords)
		);
		localStorage.setItem(
			"locations:coordinatesLastUpdated",
			this.coordinatesLastUpdated.toString()
		);
	}

	private orderLocationByDistance$(
		locations$: Observable<ServiceLocationData[]>,
		lat: number,
		lon: number,
		maxDistanceKm?: number
	): Observable<{ location: ServiceLocationData; distance: number }[]> {
		return locations$
			.pipe(
				map((locations) => {
					const output = [];
					//calc distance for all and filter out max distance
					locations.forEach((location) => {
						const object = {
							location,
							distance: this.CircleDistanceFormula(
								lat,
								lon,
								location.lat,
								location.lon
							),
						};

						if (
							maxDistanceKm == undefined ||
							object.distance < maxDistanceKm
						) {
							output.push(object);
						}
					});

					//sort base on distances
					output.sort((a, b) => {
						return a.distance - b.distance;
					});

					return output;
				})
			)
			.pipe(
				map((locations) => {
					//convert all km to mi
					for (let i = 0; i < locations.length; i++) {
						locations[i].distance *= 0.621371;
					}
					return locations;
				})
			);
	}

	/**
	 * Will order the Service Location list in from least distant to most distance, and remove anything farther than maxDistance
	 *
	 * @param lat Current Location latitude
	 * @param lon Current Location Longitude
	 * @param maxDistanceKm (optional) Max Distance from current location in KM
	 */
	public getServiceLocationsByDistance$(
		lat: number,
		lon: number,
		maxDistanceKm?: number
	): Observable<{ location: ServiceLocationData; distance: number }[]> {
		// this is to get it to trigger when servie locations change.. probably a cleaner way to do it, but ugh i'm done.
		return this.getServiceLocations$().pipe(
			switchMap((locations) => {
				if (
					!this.cachedOrderedLocations ||
					!this.cachedOrigin ||
					this.CircleDistanceFormula(
						lat,
						lon,
						this.cachedOrigin.lat,
						this.cachedOrigin.lon
					) > 0.1
				) {
					this.cachedOrigin = { lat, lon };
					this.cachedOrderedLocations = this.orderLocationByDistance$(
						this.getServiceLocations$(),
						lat,
						lon,
						maxDistanceKm
					);
				}
				console.log("reloading ordered locations");
				return this.cachedOrderedLocations;
			})

		);
		
	}

	/**
	 * A function used to find the distance from one point in
	 *
	 * @param lat1 latitude of pos 1
	 * @param lon1 longitude of pos 1
	 * @param lat2 latitude of pos 2
	 * @param lon2 longitude of pos 2
	 *
	 * @returns the distance in km from pos1 to pos2
	 */
	public CircleDistanceFormula(
		lat1: number,
		lon1: number,
		lat2: number,
		lon2: number
	): number {
		if (lat1 == null || lon1 == null || lat2 == null || lon2 == null)
			return Number.MAX_SAFE_INTEGER;

		const p = 0.017453292519943295; // Math.PI / 180
		const c = Math.cos;
		const a =
			0.5 -
			c((lat2 - lat1) * p) / 2 +
			(c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

		return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
	}
}
