import { SwitchboardClient } from "./switchboard-client.service";
import { NetworkService } from "./network.service";
import { Apollo, gql } from "apollo-angular";
import { Asset, Guide } from "./types/types";
import { take, map, catchError } from "rxjs/operators";
import { BehaviorSubject, Observable, of, from } from "rxjs";
import { Injectable } from "@angular/core";
import { persistAsync, createKey } from "./data/persistence";

@Injectable({
	providedIn: "root",
	})
export class GuidesService {
	indexedGuides$: BehaviorSubject<any>;

	private _allGuides$: Observable<Asset[]>;
	public _userGuides$: Observable<Asset[]>;

	private _dashBoardIndicators$: Observable<Asset[]>;
	private _maintenanceVideoSeries$: Observable<Asset[]>;

	constructor(
		private apollo: Apollo,
		private netsvc: NetworkService,
		private sb: SwitchboardClient
	) {}

	getUserGuides$() {
		if (!this._userGuides$) {
			this._userGuides$ = from(
				persistAsync(
					createKey("guides"),
					this.getAllGuides$()
						.pipe(
							map((guides) => {
								return guides;
							}),
							take(1),
							catchError((err) => {
								console.error(
									"Error getting user guides.",
									err
								);
								return of(null);
							})
						)
						.toPromise(),
					this.netsvc
				)
			);
		}
		return this._userGuides$;
	}

	setupGuides() {
		this.getUserGuides$();
	}

	getAllGuides$() {
		if (!this._allGuides$) {
			//get guides from server
			this._allGuides$ = this.apollo
				.watchQuery<any>({
					query: gql`
						{
							assetCategory(key: "quickGuides") {
								key
								name
								parent
								assets {
									id
									assetType
									name
									url
									customFields
									file {
										id
										url
										filename
										size
									}
									thumbnail {
										id
										url
										filename
										size
									}
									textContent
								}
							}
						}
					`,
				})
				.valueChanges.pipe(
					take(1),
					map(({ data }) => {
						return data.assetCategory.assets;
					})
				);
			// this._allGuides$ = of(MOCK_GUIDES);
		}
		return this._allGuides$;
	}

	private createIndexedGuides() {
		if (!this.indexedGuides$) {
			this.indexedGuides$ = new BehaviorSubject({});
		}
		const output = {};
		this.getUserGuides$()
			.pipe(take(1))
			.subscribe((guides) => {
				guides.forEach((guide) => {
					output[guide.id] = guide;
				});
				this.indexedGuides$.next(output);
			});
	}

	getIndexedGuides$() {
		if (!this.indexedGuides$) {
			this.createIndexedGuides();
		}
		return this.indexedGuides$;
	}

	getGuideById(id) {
		return this.getIndexedGuides$().pipe(map((guides) => guides[id]));
	}
	getGuideByPath(path) {
		return this.getUserGuides$().pipe(map((guides: Guide[]) => guides.find((guide) => guide.customFields.path === path)));
	}

	// dashboard indicators
	getDashBoardIndicators$() {
		return setupUniqueGuideAssetCategories("dashLightIndicators", this._dashBoardIndicators$, this.sb, this.netsvc);
	}

	// maintenance video series
	getMaintenanceVideoSeries$() {
		return setupUniqueGuideAssetCategories("maintenanceVideoSeries", this._maintenanceVideoSeries$, this.sb, this.netsvc);
	}
}

function setupUniqueGuideAssetCategories(key: string, variable: Observable<Asset[]>, sbInstance: SwitchboardClient, netsvcInstance: NetworkService) {
	if (!variable) {
		variable = from(
			persistAsync(
				createKey(key),
				sbInstance
					.query("assetCategory", { key: key })
					.pipe(
						take(1),
						map((data) => {
							const assets: Asset[] = data.assets.filter(
								(asset: Asset) => {
									return asset.file != null;
								}
							);
							return assets;
						}),
						catchError((err) => {
							console.error(
								`Error getting ${key}.`,
								err
							);
							return of(null);
						})
					)
					.toPromise(),
				netsvcInstance
			)
		);
	}
	return variable;
}


const MOCK_GUIDES = [
	{
		__typename: "Asset",
		id: "627bafe98a6984014344ce6e",
		assetType: "VIDEO",
		name: "Dash Lights",
		customFields: {
			assetCategory: "dashLightIndicators",
		},
		file: null,
		thumbnail: {
			__typename: "File",
			id: "627bb0038a6984014344ce73",
			url: "https://cdn-fccchq.fergdev.com/001/98bf17bd7b1fd0cc514f457372d612b4/guides-dash-lights.jpg",
			filename: "guides-dash-lights.jpg",
			size: 402061,
		},
		textContent: null,
	},
	{
		assetType: "VIDEO",
		customFields: {},
		file: {
			__typename: "File",
			id: "6318f9415a9d54651f551c9c",
			url: "./assets/media/FCCC836-Explainer-AppVersion.mp4",
			filename: "FCCC836-Explainer-AppVersion.mp4",
			size: 26069196,
		},
		id: "627bb0658a6984014344ce79",
		name: "DriveTech Controls",
		textContent: null,
		thumbnail: {
			__typename: "File",
			id: "627bb0788a6984014344ce7e",
			url: "https://cdn-fccchq.fergdev.com/001/7a9bc1ebd9c6be69267255564eb45440/guides-drivetech-controls.jpg",
			filename: "guides-drivetech-controls.jpg",
			size: 1177371,
		},
		__typename: "Asset",
	},
	{
		__typename: "Asset",
		id: "627bb0818a6984014344ce80",
		assetType: "VIDEO",
		name: "My Maintenance Guide",
		customFields: {},
		file: null,
		thumbnail: {
			__typename: "File",
			id: "627bb0998a6984014344ce86",
			url: "https://cdn-fccchq.fergdev.com/001/9f9a570d30cdafaf92697fb98ff39cec/guides-pretrip-checklist.jpg",
			filename: "guides-pretrip-checklist.jpg",
			size: 1003293,
		},
		textContent: null,
	},
];
