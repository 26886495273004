import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ApolloModule } from "apollo-angular";
import { MicroSentryModule } from "@micro-sentry/angular";

import { pageAnimation } from "./animations/page.animation";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GraphQLModule } from "./services/gql/graphql.module";
import { environment } from "src/environments/environment";

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({ navAnimation: pageAnimation }),
		MicroSentryModule.forRoot({ 
			dsn: environment.sentry.dsn,
			environment: environment.sentry.environment
		}),
		AppRoutingModule,
		HttpClientModule,
		HttpClientJsonpModule,
		GraphQLModule,
		ApolloModule,
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		AppVersion,
	],
	bootstrap: [AppComponent],
	})

export class AppModule {}
