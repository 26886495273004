import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { MainGuard } from "./services/guards/main.guard";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

const routes: Routes = [
	{
		path: "",
		redirectTo: "main",
		pathMatch: "full",
	},
	{
		path: "login",
		canActivate: [MainGuard],
		loadChildren: () =>
			import("./pages/login/login.module").then((m) => m.LoginModule),
	},
	{
		path: "main",
		canActivate: [MainGuard],
		loadChildren: () =>
			import("./pages/main/main.module").then((m) => m.MainPageModule),
	},
	{
		path: "registration",
		canActivate: [MainGuard],
		loadChildren: () =>
			import("./pages/registration/registration.module").then(
				(m) => m.RegistrationPageModule
			),
	},
	{
		path: "register",
		redirectTo: "registration",
		pathMatch: "full",
	},
	{
		path: "forgot-password",
		canActivate: [MainGuard],
		loadChildren: () =>
			import("./pages/forgot-password/forgot-password.module").then(
				(m) => m.ForgotPasswordPageModule
			),
	},
	{
		path: "reset-password",
		canActivate: [MainGuard],
		loadChildren: () => import("./pages/reset-password/reset-password.module").then( m => m.ResetPasswordPageModule)
	},
	{
		path: "**", 
		canActivate: [MainGuard],
		loadChildren: () =>
			import("./pages/main/main.module").then((m) => m.MainPageModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
