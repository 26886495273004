import { Preferences } from "@capacitor/preferences";
import { HttpClientModule } from "@angular/common/http";
import { Injector, NgModule } from "@angular/core";
import {
	ApolloLink,
	defaultDataIdFromObject,
	InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { MicroSentryService } from "@micro-sentry/angular";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { SwitchboardAuth } from "src/app/services/switchboard-auth.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import { ToastService } from "../toast.service";

export function createApollo(
	httpLink: HttpLink,
	toastService: ToastService,
	microSentry: MicroSentryService,
	injector: Injector
) {
	const uri = `${environment.switchboardOptions.apiServer}/graphql`;
	const auth = setContext((_operation, context) => {
		const authSvc = injector.get(SwitchboardAuth);
		const token = authSvc.getToken();
		const headers = { ...context.headers };

		if (token && token.length) headers.Authorization = `Bearer ${token}`;

		return { headers };
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, locations, path }) => {
				console.error(
					`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
						locations
					)}, Path: ${path}`
				);
				microSentry.captureMessage(
					`GraphQL Error: Message: ${message}, Location: ${JSON.stringify(
						locations
					)}, Path: ${path}`
				);
			});

			// if auth error, clear token and redirect to login
			if (
				graphQLErrors.some((err) => err.message === "must be logged in")
			) {
				// unregister push notifications
				const auth = injector.get(SwitchboardAuth);
				const userSvc = injector.get(UserService);
				auth.attemptRefreshToken(() => {
					auth.clearToken();
					userSvc.logout();
				});
			}
		}

		if (networkError) {
			console.error(
				`[Network error]: ${networkError.message}. API is unreachable.`
			);
			setTimeout(() => {
				toastService.toast("Problem connecting to the server.");
			}, 200); // adding delay here to make sure this is shown on top of other inline toast messages
			microSentry.captureMessage(
				`Network Error: ${networkError.message}. API is unreachable.`
			);
		}
	});

	return {
		link: ApolloLink.from([errorLink, auth, httpLink.create({ uri })]),
		cache: new InMemoryCache({
			typePolicies: {
				User: {
					fields: {
						ownership: {
							merge(_existing, incoming) {
								return incoming;
							},
						},
					},
				},
			},
			dataIdFromObject: (object: any) => {
				switch (object.__typename) {
					case "User":
						// console.log("using email address as dataId for User");
						if (object.email) return object.email;
						break;
					case "Ownership":
						// console.log("using email address as dataId for User");
						if (object.email) return object.email;
						break;
					default:
						return defaultDataIdFromObject(object);
				}
			},
		}),
	};
}

@NgModule({
	imports: [HttpClientModule],
	providers: [
	{
	provide: APOLLO_OPTIONS,
	useFactory: createApollo,
	deps: [HttpLink, ToastService, MicroSentryService, Injector],
	},
	],
	})
export class GraphQLModule {}
