import { GuidesService } from "./guides.service";
import { LocationsService } from "src/app/services/locations.service";
import { Injectable } from "@angular/core";
import { OfflineFilesService } from "./offline-files.service";
import { UserService } from "./user.service";
import { filter, take } from "rxjs/operators";

@Injectable({
	providedIn: "root",
	})
export class PreloadService {
	constructor(
		private offlineFiles: OfflineFilesService,
		private loc: LocationsService,
		private guides: GuidesService,
		private userSvc: UserService
	) {}

	// TODO: Maybe store a session variable or something so these are only preloaded once per session
	public preload(): void {
		
		// preload files only after user is logged in.
		this.userSvc
		.getUser$()
		.pipe(
			filter((user) => !!user),
			take(1)
		)
		.toPromise()
		.then(() => {
			this.preloadData();
			this.preloadFiles();
		});
	}

	private async preloadData(): Promise<void> {
		const promises = [];
		//loading locations
		promises.push(this.loc.setupLocations());
		promises.push(this.guides.setupGuides());

		await Promise.all(promises);
	}

	private async preloadFiles(): Promise<void> {
		const promises = [];

		promises.push(this.offlineFiles.updateFiles());

		await Promise.all(promises);
	}
}
