import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { SwitchboardAuth } from "../switchboard-auth.service";
import { UserService } from "../user.service";
import { take, map, distinctUntilChanged } from "rxjs/operators";
import { checkIfLoginPath } from "./route-helper";

@Injectable({
	providedIn: "root",
	})
export class MainGuard implements CanActivate {
	constructor(
		private auth: SwitchboardAuth,
		private user: UserService,
		private router: Router
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.auth.token$.pipe(
			distinctUntilChanged(),
			map((res) => {
				const path = next.routeConfig.path;
				if (res) {
					if (checkIfLoginPath(path)) {
						return this.router.createUrlTree(["/main"]);
					}
					return true;
				} else {
					if (checkIfLoginPath(path)) {
						return true;
					}
					const email = next.queryParamMap.get("email");
					const token = next.queryParamMap.get("token");

					if (!email || !token) {
						return this.router.createUrlTree(["/login"]);
					} else {
						return this.router.createUrlTree(
							["/reset-password"],
							{
								queryParams: {
									email: email,
									token: token,
								},
							}
						);
					} 
				}
			})
		);
	}
}
