import { NetworkService } from "./network.service";
import { take, map, tap } from "rxjs/operators";
import { Observable, forkJoin, from } from "rxjs";
import { Injectable } from "@angular/core";
import { Asset, Manual } from "./types";
import { Apollo, ApolloBase, gql, QueryRef } from "apollo-angular";
import { createKey, persistAsync } from "./data/persistence";

@Injectable({
	providedIn: "root",
})
export class ManualLibraryService {
	public allManuals$: Observable<Manual[]>;
	public allWarranties$: Observable<any[]>;
	public userManuals$: Observable<Manual[]>;

	constructor(private apollo: Apollo, private netsvc: NetworkService) {}

	getAllManuals$(): Observable<Manual[]> {
		if (!this.allManuals$) {
			this.allManuals$ = from(
				persistAsync(
					createKey("allManuals"),
					this.apollo
						.watchQuery<any>({
							query: gql`
								{
									assetCategory(key: "ownersManuals") {
										key
										name
										parent
										assets {
											id
											assetType
											name
											url
											file {
												id
												url
												filename
												size
											}
											thumbnail {
												id
												url
												filename
												size
											}
											caption
											description
											preview {
												url
											}
										}
									}
								}
							`,
						})
						.valueChanges.pipe(
							take(1),
							map(({ data }) => data.assetCategory.assets)
						)
						.toPromise(),
					this.netsvc
				)
			);
		}
		return this.allManuals$;
	}

	getAllWarranties$(): Observable<Asset[]> {
		if (!this.allWarranties$) {
			this.allWarranties$ = this.apollo
				.watchQuery<any>({
					query: gql`
						{
							assetCategory(key: "warranty") {
								key
								name
								parent
								assets {
									id
									assetType
									name
									url
									file {
										id
										url
										filename
										size
									}
									thumbnail {
										id
										url
										filename
										size
									}
									caption
									description
									preview {
										url
									}
								}
							}
						}
					`,
				})
				.valueChanges.pipe(
					take(1),
					map(({ data }) => data.assetCategory.assets)
				);
		}
		return this.allWarranties$;
	}

	getAllLinks$(): Observable<Asset[]> {
		return from(
			persistAsync(
				createKey("allManualLinks"),
				this.apollo
					.watchQuery<any>({
						query: gql`
							{
								assetCategory(key: "manualLinks") {
									key
									name
									parent
									assets {
										id
										assetType
										name
										url
										customFields
										file {
											id
											url
											filename
											size
										}
										thumbnail {
											id
											url
											filename
											size
										}
										caption
										description
										preview {
											url
										}
									}
								}
							}
						`,
					})
					.valueChanges.pipe(
						take(1),
						map(({ data }) => {
							return data.assetCategory.assets;
						})
					)
					.toPromise(),
				this.netsvc
			)
		);
	}

	getAllLibrary$(): Observable<any[]> {
		return forkJoin([this.getAllLinks$()]);
	}
}
